import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import rotasAplicacao from '@/router/rotasAplicacao';
import rotasAutenticacao from './rotasAutenticacao';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Aplicacao',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/AppLayout.vue'),
    children: rotasAplicacao,
  },
  {
    path: '',
    name: 'Autenticacao',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/AutenticacaoLayout.vue'),
    children: rotasAutenticacao,
  },
  {
    path: '/candidatos/novo/:parceiro',
    name: 'Novo Candidato',
    meta: {
      titulo: 'Novo Candidato',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "candidatos" */ '@/views/Candidatos/CandidatoPublico.vue'),
  },
  {
    path: '/solicitacao-nao-encontrada',
    name: 'SolicitacaoNaoEncontrada',
    meta: {
      titulo: 'Solicitação não encontrada!',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/SolicitacaoNaoEncontrada.vue'),
  },
  {
    path: '/desculpe-nos',
    name: 'DesculpeNos',
    meta: {
      titulo: 'Desculpe-nos!',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/DesculpeNos.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `Administração » ${to.meta.titulo}`;
  next();
});

export default router;
