import { RouteRecordRaw } from 'vue-router';

const rotasAutenticacao: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Autenticacao',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          titulo: 'Login',
          identificadorRecurso: '',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "core-login" */ '@/core/views/Login.vue'),
      },
    ],
  },
];

export default rotasAutenticacao;
