import { RouteRecordRaw } from 'vue-router';

const rotasAplicacao: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Aplicacao',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'sem-privilegio',
        name: 'SemPrivilegio',
        meta: {
          titulo: 'Você não possui autorização!',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "core" */ '@/core/views/SemPrivilegio.vue'),
      },
      {
        path: 'configuracoes',
        name: 'Configuracoes',
        meta: {
          titulo: 'Configurações',
          identificadorPermissao: 'CONFIGURACOES',
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ '@/views/Configuracoes.vue'),
      },
      {
        path: 'configuracoes-emails',
        name: 'ConfiguracoesEmails',
        meta: {
          titulo: 'Configurações de E-mails',
          identificadorPermissao: 'CONFIGURACOES',
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ '@/views/ConfiguracoesEmails.vue'),
      },
      {
        path: 'meu-negocio',
        name: 'MeuNegocio',
        meta: {
          titulo: 'Meu Negócio',
          identificadorPermissao: 'MEU_NEGOCIO',
        },
        component: () => import(/* webpackChunkName: "configuracoes" */ '@/views/MeuNegocio.vue'),
      },
      {
        path: 'usuarios',
        name: 'Usuarios',
        meta: {
          titulo: 'Usuários',
          identificadorPermissao: 'USUARIOS',
        },
        component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/UsuarioListagem.vue'),
      },
      {
        path: 'usuarios/cadastrar',
        name: 'UsuarioCadastroCriar',
        meta: {
          titulo: 'Cadastrar Usuário',
          identificadorPermissao: 'USUARIOS',
        },
        component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/UsuarioCadastro.vue'),
      },
      {
        path: 'usuarios/:codigo',
        name: 'UsuarioCadastroEditar',
        meta: {
          titulo: 'Editar Usuário',
          identificadorPermissao: 'USUARIOS',
        },
        component: () => import(/* webpackChunkName: "categorias" */ '@/views/Usuarios/UsuarioCadastro.vue'),
      },
      {
        path: 'usuarios/meus-dados',
        name: 'UsuarioMeusDados',
        meta: {
          titulo: 'Meus Dados',
          identificadorPermissao: '',
        },
        component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Usuarios/MeusDados.vue'),
      },
      {
        path: 'candidatos',
        name: 'Candidatos',
        meta: {
          titulo: 'Candidatos',
          identificadorPermissao: 'CANDIDATOS',
        },
        component: () => import(/* webpackChunkName: "candidatos" */ '@/views/Candidatos/CandidatoListagem.vue'),
      },
      {
        path: 'candidatos/cadastrar',
        name: 'CandidatoCadastroCriar',
        meta: {
          titulo: 'Cadastrar Candidato',
          identificadorPermissao: 'CANDIDATOS',
        },
        component: () => import(/* webpackChunkName: "candidatos" */ '@/views/Candidatos/CandidatoCadastro.vue'),
      },
      {
        path: 'candidatos/:codigo',
        name: 'CandidatoCadastroEditar',
        meta: {
          titulo: 'Editar Candidato',
          identificadorPermissao: 'CANDIDATOS',
        },
        component: () => import(/* webpackChunkName: "candidatos" */ '@/views/Candidatos/CandidatoCadastro.vue'),
      },
      {
        path: 'setores',
        name: 'Setores',
        meta: {
          titulo: 'Setores',
          identificadorPermissao: 'SETORES',
        },
        component: () => import(/* webpackChunkName: "setores" */ '@/views/Setores/SetorListagem.vue'),
      },
      {
        path: 'setores/cadastrar',
        name: 'SetorCadastroCriar',
        meta: {
          titulo: 'Cadastrar Setor',
          identificadorPermissao: 'SETORES',
        },
        component: () => import(/* webpackChunkName: "setores" */ '@/views/Setores/SetorCadastro.vue'),
      },
      {
        path: 'setores/:codigo',
        name: 'SetorCadastroEditar',
        meta: {
          titulo: 'Editar Setor',
          identificadorPermissao: 'SETORES',
        },
        component: () => import(/* webpackChunkName: "setores" */ '@/views/Setores/SetorCadastro.vue'),
      },
      {
        path: 'grau-instrucoes',
        name: 'GrauInstrucoes',
        meta: {
          titulo: 'Grau de Instruções',
          identificadorPermissao: 'GRAU-INSTRUCOES',
        },
        component: () => import(/* webpackChunkName: "grau-instrucoes" */ '@/views/GrauInstrucoes/GrauInstrucaoListagem.vue'),
      },
      {
        path: 'grau-instrucoes/cadastrar',
        name: 'GrauInstrucaoCadastroCriar',
        meta: {
          titulo: 'Cadastrar Grau de Instrução',
          identificadorPermissao: 'GRAU-INSTRUCOES',
        },
        component: () => import(/* webpackChunkName: "grau-instrucoes" */ '@/views/GrauInstrucoes/GrauInstrucaoCadastro.vue'),
      },
      {
        path: 'grau-instrucoes/:codigo',
        name: 'GrauInstrucaoCadastroEditar',
        meta: {
          titulo: 'Editar Grau de Instrução',
          identificadorPermissao: 'GRAU-INSTRUCOES',
        },
        component: () => import(/* webpackChunkName: "grau-instrucoes" */ '@/views/GrauInstrucoes/GrauInstrucaoCadastro.vue'),
      },
    ],
  },
];

export default rotasAplicacao;
